<template>
  <div>
    <PageTitle v-bind:title="'Reviews'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button
            v-if="config.editable"
            variant="primary"
            size="sm"
            :disabled="!$store.getters.checkRole('legam.bib.review.add')"
            class="float-right mb-4"
            v-b-modal.modal-new-review
          >
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new review
          </b-button>
          <b-modal
            v-if="config.editable"
            id="modal-new-review"
            title="New review"
            ok-title="Save"
            @ok="saveReview"
            size="lg"
          >
            <ReviewNewEdit />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
* >>> .tdAction {
  width: 150px;
}
</style>

<script>
// import { mapState } from 'vuex'
import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
import LegamDataTables from "@/components/LegamDataTables.vue";
import LegamDataTablesClientSide from "@/components/LegamDataTablesClientSide.vue";
import tdColAction from "@/components/tdColAction";
// import tdColSafeHTML from "@/components/tdColSafeHTML"
import ReviewNewEdit from "@/components/Bibliography/ReviewNewEdit";
import HelpStore from "@/store/helpstore.js";
import config from '@/config.js'

export default {
  name: "Reviews",
  data() {
    return {
      columns: [
        {
          title: this.$t("BibDescription"),
          field: "bibDescription",
          sortable: true,
          searchable: true,
        },
        {
          title: this.$t("RevEditions"),
          field: "editions",
          sortable: true,
          searchable: true,
        },
        {
          title: "Comment General",
          field: "commentGeneral",
          sortable: true,
          searchable: true,
        },
        {
          title: this.$t("Authors"),
          field: "authors",
          sortable: true,
          searchable: true,
        },
        {
          title: "Action",
          field: "id",
          tdComp: tdColAction,
          thClass: "tdAction",
          transformData: function (row) {
            return [
              {
                text: null,
                icon: "list-ul",
                id: row.id,
                to: {
                  name: "ReviewDetails",
                  params: {
                    id: row.id,
                  },
                },
              },
            ];
          },
        },
      ],
      columnsPI: [
        {
          title: this.$t("BibDescription"),
          field: "bibDescription",
          sortable: true,
          searchable: true,
        },
        {
          title: this.$t("CommentGeneral"),
          field: "commentGeneral",
          sortable: true,
          searchable: true,
        },
        {
          title: this.$t("Authors"),
          field: "authors",
          sortable: true,
          searchable: true,
        }
      ],
      url: "/reviews/datatables",
      searchableByColumn: true,
      config: config
    };
  },
  components: {
    PageTitle,
    LegamDataTables,
    LegamDataTablesClientSide,
    ReviewNewEdit,
  },
  methods: {
    saveReview() {
      this.$store.dispatch("createReview", HelpStore.item).then((response) => {
        router.push(`/review/${response.data.id}`);
      });
    },
    openDetails(data) {
      router.push(`/review/${data.id}`)
    },
  },
};
</script>
