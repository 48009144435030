<template>
  <div>
    <b-form-group id="bib-description" label="Bib Description:" label-for="bibDescription">
      <b-form-input
        id="bibDescription"
        v-model="model.bibDescription"
        placeholder="Bib Description"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="comment-general" label="Comment General" label-for="commentGeneral">
      <b-form-input
        id="commentGeneral"
        v-model="model.commentGeneral"
        placeholder="Comment General"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="type-group" label="Last name" label-for="lastname">
      <AuthorSelect @select="addAuthors" />
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
import HelpStore from "@/store/helpstore.js";
import AuthorSelect from '@/components/Bibliography/AuthorSelect.vue'

export default {
  name: "ReviewNewEdit",
  props: ["review"],
  data() {
    return {
      model: this.review
        ? this.review
        : {
            id: null,
            bibDescription: "",
            commentGeneral: "",
            authors: []
          },
    };
  },
  components: {
    AuthorSelect
  },
  methods: {
    addAuthors(authors) {
      this.model.authors = authors
    },
  },
  mounted() {
    HelpStore.item = this.model;
  },
};
</script>
